import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const PageContainer = styled.div`
  min-height: 100vh;
  background: #f8f9fa;
`;

const HeroSection = styled.section`
  position: relative;
  height: 30vh;
  min-height: 300px;
  background: #8a2be2;
  color: white;
  overflow: hidden;
  margin-bottom: 4rem;
  
  @media (max-width: 1024px) {
    height: 50vh;
    min-height: 300px;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top:0; left:0;
  width:100%; height:100%;
  background: purple;
  opacity: 0.3;
  mix-blend-mode: color; /* Try hue, color, multiply, overlay, etc. */
`;
const HeroBackground = styled.div`
 position: absolute;
inset: 0;

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  /* Adjust the hue rotation and reduce saturation impact */
  filter: hue-rotate(290deg) saturate(0.8) brightness(1.1);
  transform: scale(1.1);
  transition: transform 20s ease;

  @media (max-width: 1024px) {
    height: 100%;
    object-position: center;
    transform: scale(1.0);
  }

  @media (max-width: 480px) {
    object-position: center center;
  }

  &:hover {
    transform: scale(1.2);

    @media (max-width: 1024px) {
      transform: scale(1.1);
    }
  }
}

&::after {
  display: none;
}
`;
const HeroContent = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;

  /* Style for the h1 tag */
  h1 {
    font-size: 4.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    background: linear-gradient(135deg, #9932cc, #8a2be2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -1px;
    
    @media (max-width: 1024px) {
      font-size: 2.5rem;
      padding: 0 1rem;
    }
    
    @media (max-width: 480px) {
      font-size: 2rem;
    }
  }

  /* Style for the h2 tag */
  h2 {
    font-size: 2rem;
    color: #9932cc;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    font-weight: 400;
    max-width: 600px;
    line-height: 1.4;
    margin-bottom: 4rem;
    
    @media (max-width: 1024px) {
      font-size: 1.5rem;
      padding: 0 1rem;
      margin-bottom: 1.5rem;
    }
    
    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  }

  /* Add a rule for the img directly */
  img {
      width: auto;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

    @media (max-width: 1024px) {
      max-width: 150px;
    }
    
    @media (max-width: 480px) {
      max-width: 100px;
    }
  }
`;
const LogoContainer = styled.div`
  position: absolute;
  left: 2rem;
  
  @media (max-width: 1024px) {
    position: static;
  }
`;

const Logo = styled.div`
  text-align: center;
  color: white;
  flex-grow: 1;
  
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
  }
`;

const LogoImage = styled.img`
  width: 50%;
  height: 50%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;
const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 10;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const Card = styled.div`
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.12);
  }
`;

const CardHeader = styled.div`
  padding: 2rem;
  background: linear-gradient(135deg, #9932cc, #8a2be2);
  
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: white;
    margin: 0;
  }
`;

const CardContent = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const EventLetters = styled.div`
  color: #8a2be2;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.5rem 0;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const EventCard = styled.article`
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(0,0,0,0.05);
  
  &:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
  }
`;

const EventHeader = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const EventImage = styled.img`
  width: 250px;
  height: 180px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  
  @media (max-width: 640px) {
    width: 100%;
    height: 200px;
  }
`;

const EventTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: #333;
  line-height: 1.3;
  flex: 1;
`;

const EventDetails = styled.div`
  .date {
    color: #8a2be2;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    &:before {
      content: '📅';
    }
  }
  
  .description {
    color: #666;
    line-height: 1.7;
    font-size: 1.1rem;
  }
`;

const ViewMoreLink = styled.a`
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  color: #8a2be2;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  gap: 0.5rem;
  padding: 0.5rem 0;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #8a2be2;
    transition: width 0.3s ease;
  }
  
  &:hover:after {
    width: 100%;
  }
  
  &:hover {
    color: #7825c4;
  }
`;


const LandingPage = () => {
  const [eventos, setEventos] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    const fetchData = async () => {
      try {
        const [eventosResponse, noticiasResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/agendas`, {
            params: {
              'populate': ['Image'],
              'sort[0]': 'Date:desc',
              'pagination[page]': 1,
              'pagination[pageSize]': 3
            },
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${apiUrl}/api/hemerotecas`, {           
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        setEventos(eventosResponse.data.data.slice(0, 3));
        setNoticias(noticiasResponse.data.data.slice(0, 3));
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '';
      
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  const getImageUrl = (item) => {
    if (!item?.attributes?.ImagePath) {
      return null;
    }
    return `${item.attributes.ImagePath}`;
  };

  return (
    <PageContainer>
     
  
        
        <HeroContent>
        <img src="/logo.jpeg" alt="Logo" />
          <h1>Tertulia Feminista Les Comadres</h1>
          <h2>Activistas feministas unidas desde 1986</h2>
         
        </HeroContent>
        
        
        <HeroSection>
        <HeroBackground>
          <img src="/letronas.jpg" alt="Gijón sign" />
          <Overlay />
        </HeroBackground>
      </HeroSection>

      <MainContent>
      
        <ContentGrid>
          <Card>
            <CardHeader>
              <h2>Próximos Eventos</h2>
            </CardHeader>
            <CardContent>
              {eventos.length > 0 ? (
                eventos.map((evento) => (
                  <EventCard key={evento.attributes.id}>
                    <EventLetters>Les Comadres</EventLetters>
                    <EventHeader>
                      {evento.attributes.ImagePath && (
                        <EventImage
                          src={`${evento.attributes.ImagePath}`}
                          alt={evento.attributes.Title || 'Imagen del evento'}
                          onError={(e) => {
                            e.target.style.display = 'none';
                          }}
                        />
                      )}
                      <EventTitle>{evento.attributes.Title}</EventTitle>
                    </EventHeader>
                    <EventDetails>
                      <div className="date">
                        {formatDate(evento.attributes. Date)}
                      </div>
                      <p className="description">{evento.attributes.Description}</p>
                      <ViewMoreLink href="/agenda">Ver más detalles →</ViewMoreLink>
                    </EventDetails>
                  </EventCard>
                ))
              ) : (
                <p>No hay eventos próximos disponibles.</p>
              )}
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <h2>Noticias</h2>
            </CardHeader>
            <CardContent>
              {noticias.length > 0 ? (
                noticias.map((noticia) => (
                  <EventCard key={noticia.attributes.id}>
                    <EventHeader>
                      <EventTitle>{noticia.attributes.  titulo}</EventTitle>
                    </EventHeader>
                    <EventDetails>
                      <div className="date">
                        {formatDate(noticia.attributes.fecha)}
                      </div>
                      <p className="description">{noticia.attributes.descripcion}</p>
                      <ViewMoreLink href="/hemeroteca/noticias-propias">Ver más →</ViewMoreLink>
                    </EventDetails>
                  </EventCard>
                ))
              ) : (
                <p>No hay noticias disponibles.</p>
              )}
            </CardContent>
          </Card>
        </ContentGrid>
      </MainContent>

    </PageContainer>
  );
};

export default LandingPage;
